import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCompanies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/company', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompanyGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/company-group', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
            .get('/admin/role', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCompany(ctx, companyData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/company', companyData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCompanyGroup(ctx, companyGroupData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/company-group', companyGroupData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
